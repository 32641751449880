<template>
  <b-modal
    id="modal_cambiar_mi_contrasenia"
    ok-only
    size="md"
    header-bg-variant="primary"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          Cambiar mi contraseña
        </strong></div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="actualPassword"
              rules="required"
            >
              <b-form-group
                label="Actual contraseña"
                label-for="actualPassword"
              >
                <b-form-input
                  id="actualPassword"
                  v-model="actualPassword"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="nuevoPassword"
              rules="required"
            >
              <b-form-group
                label="Nueva contraseña"
                label-for="nuevoPassword"
              >
                <b-form-input
                  id="nuevoPassword"
                  v-model="nuevoPassword"
                  type="password"
                  :state="estadoCampoNuevoPassword()"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="repetirNuevoPassword"
              rules="required"
            >
              <b-form-group
                label="Repetir nueva contraseña"
                label-for="repetirNuevoPassword"
              >
                <b-form-input
                  id="repetirNuevoPassword"
                  v-model="repetirNuevoPassword"
                  type="password"
                  :state="estadoCampoRepetirNuevoPassword()"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo es requerido
                </b-form-invalid-feedback>
                <span
                  v-if="nuevoPassword !== repetirNuevoPassword && nuevoPassword !== '' && repetirNuevoPassword !== ''"
                  class="text-danger"
                >
                  Las contraseñas no coinciden
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-2 border-top pt-1">
          <b-button
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_cambiar_mi_contrasenia')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            :variant="`outline-success`"
            class="mr-2"
            type="submit"
          >
            Restablecer contraseña
            <feather-icon
              icon="RefreshCcwIcon"
              size="14"
            />
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    // vSelect,
    // BInputGroup,
    // BInputGroupAppend,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      actualPassword: '',
      nuevoPassword: '',
      repetirNuevoPassword: '',
      usuario: {},
      required,
      dir: 'ltr',

    }
  },
  created() {
    this.$parent.$on('abrirModalCambiarMicontrasenia', this.abrirModalCambiarMicontrasenia)
  },

  methods: {
    estadoCampoNuevoPassword() {
      if (this.nuevoPassword === '') {
        return null
      }
      if (this.repetirNuevoPassword === '') {
        return true
      }
      if (this.nuevoPassword === this.repetirNuevoPassword) {
        return true
      }
      return false
    },
    estadoCampoRepetirNuevoPassword() {
      if (this.repetirNuevoPassword === '') {
        return null
      }
      if (this.nuevoPassword === '') {
        return true
      }

      if (this.nuevoPassword === this.repetirNuevoPassword) {
        return true
      }
      return false
    },
    abrirModalCambiarMicontrasenia() {
      this.limpiarFormulario()
      this.$bvModal.show('modal_cambiar_mi_contrasenia')
    },

    onSubmit() {
      this.updateProfesor()
    },

    updateProfesor() {
      const data = {
        old_password: this.actualPassword,
        new_password: this.nuevoPassword,
      }

      this.$http.post('/usuario/password_update', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Contraseña cambiada con éxito!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          // this.$emit('actualizarTablaUsuario')
          this.$bvModal.hide('modal_cambiar_mi_contrasenia')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error en cambiar contraseña!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    limpiarFormulario() {
      this.actualPassword = ''
      this.nuevoPassword = ''
      this.repetirNuevoPassword = ''
    },

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>
