<template>
  <layout-vertical>

    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    <!-- <div
      slot="breadcrumb"
      class="p-1"
    >
      I am custom breadcrumb
    </div> -->
    <app-breadcrumb slot="breadcrumb" />
    <app-footer slot="footer" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import AppFooter from '@/layouts/components/AppFooter.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import AppNavbarVerticalLayout from '@/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'

export default {
  components: {
    // AppCustomizer,
    AppBreadcrumb,
    AppFooter,
    LayoutVertical,
    AppNavbarVerticalLayout,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
