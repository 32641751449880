import salud from './salud'
import social from './social'
import administrador from './administrador'
import estudiante from './estudiante'
import ocade from './ocade'

export default [
  {
    title: 'Panel de control',
    route: 'home',
    icon: 'HomeIcon',
  },
  ...administrador,
  ...salud,
  ...social,
  ...estudiante,
  ...ocade,
]
