export default [
  {
    header: 'Servicio de social',
    action: 'social.becas.becario.index',
    resource: 'api',
  },
  {
    title: 'Asistencia social',
    icon: 'UsersIcon',
    route: '',
    // action: '',
    // resource: 'web',
    children: [
      {
        title: 'Soporte social',
        route: 'social-soporte-list',
        action: 'social.asistencia.soporte.index',
        resource: 'api',
      },
      {
        title: 'Visitas',
        route: 'social-visitas-list',
        action: 'social.asistencia.visita.index',
        resource: 'api',
      },
      {
        title: 'Opinión Postulante',
        route: 'opinion-postulante-beca',
        action: 'social.asistencia.opinion_postulante_beca.index',
        resource: 'api',
      },
      {
        title: 'Reportes',
        route: '',
      },
    ],
  },
  {
    title: 'Hostigam. Sexual',
    icon: 'XIcon',
    action: 'social.hostigamiento.denuncias.vista',
    resource: 'api',
    children: [
      {
        title: 'Reportes',
        route: 'social-denuncias-reportes',
        action: 'social.hostigamiento.denuncias.vista',
        resource: 'api',
      },

      {
        title: 'Respuestas Denun.',
        route: 'respuestas-denuncias-hostigamiento',
        action: 'social.hostigamiento.denuncias.index',
        resource: 'api',
      },
      {
        title: 'Gestión Encuestas',
        route: 'gestion-formulario-encuesta',
        action: 'social.hostigamiento.denuncias.index',
        resource: 'api',
      },
      // {
      //   title: 'Respuestas Encuest.',
      //   route: 'respuestas-encuestas-hostigamiento',
      //   action: 'social.hostigamiento.denuncias.index',
      //   resource: 'api',
      // },
      {
        title: 'Buzón de denuncias',
        route: 'social-buzon-denuncias',
        action: 'social.hostigamiento.denuncias.index',
        resource: 'api',

      },
      {
        title: 'Denuncias',
        route: 'social-denuncias',
        action: 'social.hostigamiento.denuncias.index',
        resource: 'api',

      },

    ],
  },
  {
    title: 'Becas',
    icon: 'StarIcon',
    children: [
      {
        title: 'Convocatorias',
        route: 'social-convocatorias-list',
        action: 'social.becas.convocatoria.store',
        resource: 'api',
      },
      {
        title: 'Becarios',
        route: 'becarios-todos-list',
        action: 'social.becas.becario.store',
        resource: 'api',
      },
      {
        title: 'Infracciones',
        route: 'infracciones-becarios',
        action: 'social.infracciones.infraccion.index',
        resource: 'api',
      },
    ],
  },
  {
    title: 'Servicio comedor',
    icon: 'CoffeeIcon',
    action: 'social.becas.comedor.index',
    resource: 'api',
    children: [
      {
        title: 'Becarios',
        route: 'comedor-becarios',
        action: 'social.becas.comedor.index',
        resource: 'api',
      },
      {
        title: 'Atenciones',
        route: 'comedor-becarios-asistencias',
        action: 'social.becas.comedor.crear_registro_asistencia',
        resource: 'api',
      },
      {
        title: 'Nutrición',
        route: 'comedor-nutricion',
        action: 'social.becas.nutricion.index',
        resource: 'api',
      },
      {
        title: 'Reportes',
        route: 'comedor-reportes',
        action: 'social.becas.comedor.reporte_asistencia',
        resource: 'api',
      },
    ],
  },
  {
    title: 'Residencia univ.',
    icon: 'HomeIcon',
    action: 'social.residencia.becarios.index',
    resource: 'api',
    children: [
      {
        title: 'Becarios',
        route: 'residencia-becarios-list',
        action: 'social.residencia.becarios.index',
        resource: 'api',
      },
      {
        title: 'Ambientes',
        route: 'residencia-ambientes-list',
        action: 'social.residencia.habitacion.index',
        resource: 'api',
      },
      // {
      //   title: 'Reportes',
      //   route: '',
      // },
    ],
  },
  {
    title: 'Bolsa de trabajo',
    icon: 'BriefcaseIcon',
    route: '',
    action: 'social.becas.becario.store',
    resource: 'api',
    children: [
      {
        title: 'Becarios',
        route: 'bolsa-becarios',
        action: 'social.becas.becario.store',
        resource: 'api',
      },
      {
        title: 'Reportes',
        route: '',
      },
    ],
  },

  // SATISFACCION ESTUDIANTE
  {
    header: 'Satisfacción social',
    action: 'social.becas.comedor.index',
    resource: 'api',
  },

  {
    title: 'Satisf. Comedor',
    icon: 'DribbbleIcon',
    route: 'satisfaccion-estudiante-social',
    action: 'social.becas.comedor.index',
    resource: 'api',
  },
]
