<template>
  <div>
    <modal-cambiar-mi-contrasenia />
    <modal-actualizar-perfil
      :data-usuario="userData"
      :fotografia-usuario="userData.foto"
      :firma-usuario="userData.firma"
      @updateFotoUsuario="updateFotoUsuario"
    />
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name }}
          </p>
          <span class="user-status">{{ userData.cargo_funcion }}</span>
        </div>
        <b-avatar
          size="40"
          variant="light-primary"
          badge
          :src="userData.foto ? userData.foto : require('@/assets/images/user/foto_usuario_default.jpg')"
          class="badge-minimal"
          badge-variant="success"
        />
      </template>

      <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->
      <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item> -->
      <!-- <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

      <!-- <b-dropdown-divider /> -->

      <b-dropdown-item
        v-if="esRolEstudiante()"
        link-class="d-flex align-items-center"
        @click="actualizarPerfil"
      >
        <feather-icon
          size="16"
          icon="SmileIcon"
          class="mr-50"
        />
        <span><small>Actualizar perfil</small></span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="esRolEstudiante()"
        link-class="d-flex align-items-center"
        @click="cambiarContrasenia"
      >
        <feather-icon
          size="16"
          icon="KeyIcon"
          class="mr-50"
        />
        <span><small>Camb. contraseña</small></span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Cerrar sesión</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>

</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import ModalCambiarMiContrasenia from '@/views/administrador/cambiar-mi-contrasenia/ModalCambiarMiContrasenia.vue'
import ModalActualizarPerfil from '@/views/administrador/mi-perfil/ModalActualizarPerfil.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
    ModalCambiarMiContrasenia,
    ModalActualizarPerfil,
  },
  data() {
    return {
      userData: {},
      roles: [],
      avatarText,
    }
  },
  beforeMount() {
    this.$http.get('/usuario_datos')
      .then(response => {
        this.userData = response.data.usuario
        this.roles = response.data.roles
      })
  },
  mounted() {

  },
  methods: {
    logout() {
      localStorage.removeItem('userData')
      useJwt.logout().then(() => {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('fechaIniciadaParaAsistencia')

        // Reset ability
        this.$ability.update(initialAbility)
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      })
    },
    cambiarContrasenia() {
      this.$emit('abrirModalCambiarMicontrasenia')
    },
    actualizarPerfil() {
      this.$emit('abrirModalActualizarPerfil')
    },
    esRolEstudiante() {
      if (this.roles.some(val => val.name === 'Estudiante')) {
        return false
      }
      return true
    },
    updateFotoUsuario(data) {
      console.log('entramos a actualizar foto:', data)
      this.userData.foto = data.foto
      this.userData.firma = data.firma
    },
  },
}
</script>
