export default [
  {
    header: 'servicio de salud',
  },
  {
    title: 'General',
    icon: 'CalendarIcon',
    children: [
      // {
      //   title: 'Citas',
      //   route: 'citas.index',
      //   action: 'citas.index',
      //   resource: 'api',
      // },
      {
        title: 'Triajes',
        route: 'salud-triajes-list',
        action: 'salud.triajes.index',
        resource: 'api',
      },
      {
        title: 'Atenciones',
        route: 'salud-atencion-list',
        action: 'salud.atenciones.index',
        resource: 'api',
      },
      {
        title: 'Buscar historial',
        route: 'salud-historia-clinica-list',
        action: 'salud.historias.index',
        resource: 'api',
      },
      {
        title: 'Constancias',
        route: 'salud-constancia-list',
        action: 'salud.constancias.index',
        resource: 'api',
      },

      {
        title: 'Emergencias',
        route: 'salud-emergencia-list',
        action: 'salud.emergencias.index',
        resource: 'api',
      },
      {
        title: 'Ficha médica',
        route: 'ficha-medica',
        action: 'fichaMedica.index',
        resource: 'api',
      },
      {
        title: 'Reportes',
        route: 'salud-reporte-atenciones',
        action: 'salud.reporte.atenciones.grafico',
        resource: 'api',
      },
      {
        title: 'Derivados',
        route: 'salud-derivados-list',
        action: 'salud.derivados.index',
        resource: 'api',
      },
    ],
  },
  // {
  //   title: 'Medicina',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Pendientes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Atendidos',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Reportes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //   ],
  // },
  // {
  //   title: 'Tópico',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Pendientes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Atendidos',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Reportes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //   ],
  // },
  // {
  //   title: 'Odontología',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Pendientes',
  //       route: 'odontologias.index',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Atendidos',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Reportes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //   ],
  // },
  // {
  //   title: 'Gineco-Obstetricia',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Pendientes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Atendidos',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Reportes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //   ],
  // },
  // {
  //   title: 'Oftalmología',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Pendientes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Atendidos',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //     {
  //       title: 'Reportes',
  //       // route: 'usuarios',
  //       action: '',
  //       resource: 'api',
  //     },
  //   ],
  // },

  {
    title: 'Farmacia',
    icon: 'GridIcon',
    children: [
      {
        title: 'Medicamentos',
        route: 'farmacia.index',
        action: 'salud.farmacia.producto.index',
        resource: 'api',
      },
      {
        title: 'Ingresos',
        route: 'farmacia.input.list',
        action: 'salud.farmacia.compra.index',
        resource: 'api',
      },
      {
        title: 'Salidas',
        route: 'farmacia.ouput.list',
        action: 'salud.farmacia.venta.index',
        resource: 'api',
      },
    ],
  },

  // SATISFACCION ESTUDIANTE
  {
    header: 'Satisfacción Salud',
    action: 'salud.encuesta.satisfaccion.estudiante',
    resource: 'api',
  },

  {
    title: 'Satisfacción Sal.',
    icon: 'DribbbleIcon',
    route: 'satisfaccion-estudiante-salud',
    action: 'salud.encuesta.satisfaccion.estudiante',
    resource: 'api',
  },
]
