export default [
  {
    header: 'Talleres',
    action: 'PROF-OCADE',
    resource: 'api',
  },
  {
    title: 'Talleres a cargo',
    icon: 'DribbbleIcon',
    route: 'ocade-profesores-talleres',
    action: 'PROF-OCADE',
    resource: 'api',
  },
  // PROFESOR
  {
    header: 'Administración',
    action: 'cultural_deportivo.profesor.index',
    resource: 'api',
  },
  {
    title: 'Profesores',
    icon: 'DribbbleIcon',
    route: 'ocade-profesores',
    action: 'cultural_deportivo.profesor.index',
    resource: 'api',
  },
  {
    title: 'Satisfacción Est.',
    icon: 'DribbbleIcon',
    route: 'satisfaccion-estudiante-ocade',
    action: 'JEFE-OCADE',
    resource: 'api',
  },

  // DEPORTIVO
  {
    header: 'Servicio Deportivo',
    action: 'OCADE-DEPORTIVO',
    resource: 'api',
  },
  {
    title: 'ActividadesRecr.',
    icon: 'DribbbleIcon',
    route: 'ocade-servicio-deportivo-actividades-generales',
    action: 'OCADE-DEPORTIVO',
    resource: 'api',
  },
  {
    title: 'TalleresDep.',
    icon: 'TargetIcon',
    route: 'ocade-servicio-deportivo',
    action: 'OCADE-DEPORTIVO',
    resource: 'api',
  },
  {
    title: 'Derivados Depor.',
    route: 'derivados-deporte-list',
    icon: 'UserIcon',
    action: 'OCADE-DEPORTIVO',
    resource: 'api',
  },
  // {
  //   title: 'ReportesDep.',
  //   icon: 'UserIcon',
  //   route: '',
  // },

  // CULTURAL
  {
    header: 'Servicio Cultural',
    action: 'OCADE-CULTURAL',
    resource: 'api',
  },
  {
    title: 'ActividadesCult.',
    icon: 'DribbbleIcon',
    route: 'ocade-servicio-cultural-actividades-generales',
    action: 'OCADE-CULTURAL',
    resource: 'api',
  },
  {
    title: 'Talleres Cult.',
    icon: 'TargetIcon',
    route: 'ocade-servicio-cultural',
    action: 'OCADE-CULTURAL',
    resource: 'api',
  },
  {
    title: 'Derivados Cult.',
    route: 'derivados-cultural-list',
    icon: 'UserIcon',
    action: 'OCADE-CULTURAL',
    resource: 'api',
  },
  // {
  //   title: 'ReportesCult.',
  //   icon: 'UserIcon',
  //   route: '',
  // },

  // PRODAC
  {
    header: 'PRODAC',
    action: 'OCADE-ALT-COMP',
    resource: 'api',
  },
  {
    title: 'Eventos',
    icon: 'DribbbleIcon',
    route: 'ocade-prodac-eventos',
    action: 'OCADE-ALT-COMP',
    resource: 'api',
  },
  {
    title: 'Programas Dep.',
    icon: 'TargetIcon',
    route: 'ocade-prodac',
    action: 'OCADE-ALT-COMP',
    resource: 'api',
  },
  // {
  //   title: 'ReportesProdac',
  //   icon: 'UserIcon',
  //   // route: '',
  //   action: 'cultural_deportivo',
  //   resource: 'api',
  // },
]
