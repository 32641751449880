export default [
  {
    header: 'administrador',
  },
  {
    title: 'Usuarios',
    icon: 'UserIcon',
    children: [
      {
        title: 'Usuarios',
        route: 'usuarios-listar',
        resource: 'api',
        action: 'administrador.usuario.index',
      },
      {
        title: 'Roles',
        route: 'roles-listar',
        action: 'administrador.role.index',
        resource: 'api',
      },

    ],
  },
  {
    title: 'Formulario',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'Nuevo formulario',
        route: 'nuevo-formulario-index',
        action: 'recursos.formulario.index',
        resource: 'api',
      },
      {
        title: 'Formularios',
        route: 'listar-formularios-index',
        action: 'recursos.formulario.index',
        resource: 'api',
      },

    ],
  },
  {
    title: 'Ajustes',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Ajustes app',
        route: 'administrador-ajustes-app',
        resource: 'api',
        action: 'administrador.usuario.index',
      },
    ],
  },

]
