export default [
  {
    header: 'Vista Estudiante',
  },
  {
    title: 'Servicio Salud',
    icon: 'HeartIcon',
    children: [
      {
        title: 'Ficha médica',
        route: 'ficha-medica-estudiante',
        action: 'social.becas.convocatoria.postulante.store',
        resource: 'api',
      },
      // {
      //   title: 'Mis atenciones',
      //   route: '#',
      //   action: 'social.becas.convocatoria.postulante.store',
      //   resource: 'api',
      // },
    ],
  },
  {
    title: 'Servicio social',
    icon: 'StarIcon',
    children: [
      {
        title: 'Convocatorias',
        route: 'social-convocatorias-list',
        action: 'social.becas.convocatoria.postulante.store',
        resource: 'api',
      },
      {
        title: 'Mis postulaciones',
        route: 'mis-postulaciones-estudiante',
        action: 'social.becas.convocatoria.postulante.store',
        resource: 'api',
      },

    ],
  },
  {
    title: 'Servicio Dep y Cult',
    icon: 'DribbbleIcon',
    children: [
      {
        title: 'Mis talleres',
        route: 'mis-talleres-estudiante',
        action: 'social.becas.convocatoria.postulante.store',
        resource: 'api',
      },

    ],
  },

  // {
  //   title: 'Servicio Social',
  //   icon: 'CalendarIcon',
  //   children: [
  //     {
  //       title: 'Convocatorias',
  //       route: 'social-convocatorias-list',
  //       action: 'social.becas.convocatoria.postulante.store',
  //       resource: 'api',
  //     },
  //   ],
  // },
  // {
  //   title: 'Talleres',
  //   icon: 'CalendarIcon',
  //   children: [
  //     {
  //       title: 'Talleres abiertos',
  //       route: '#',
  //       action: '#',
  //       resource: 'web',
  //     },
  //     {
  //       title: 'Mis talleres',
  //       route: '#',
  //       action: 'social.becas.convocatoria.postulante.store',
  //       resource: 'api',
  //     },
  //   ],
  // },

]
