<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ piePagina1 }}
    </span>

    <span class="float-md-right d-none d-md-block">{{ piePagina2 }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapState('ajustesApp', ['piePagina1', 'piePagina2']),

  },
}
</script>
