<template>
  <b-modal
    id="modal_actualizar_perfil"
    ok-only
    size="lg"
    header-bg-variant="primary"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          Mi Perfil
        </strong></div>

    </template>
    <!-- BODY -->
    <b-row class="mb-1 px-1">
      <b-col cols="12">

        <div><strong>CARGO - FUNCIÓN: </strong> <span
          class="ml-2"
        >{{ dataUsuario.cargo_funcion }}</span></div>
      </b-col>
    </b-row>
    <b-row class="px-1">
      <b-col
        lg="6"
        md="12"
        sm="12"
        class="border rounded pt-1"
      >
        <b-form-group
          label="Usuario"
          label-for="usuarioFotografia"
        >
          <b-row>
            <b-col
              lg="4"
              md="6"
              sm="12"
            >
              <span>
                <b-img
                  thumbnail
                  center
                  fluid
                  :src="fotografiaUsuario ? fotografiaUsuario : require('@/assets/images/user/foto_usuario_default.jpg')"
                  alt="Image 1"
                  width="120"
                  style="margin-bottom:5px;"
                />
              </span>
            </b-col>
            <b-col
              lg="8"
              md="6"
              sm="12"
            >
              <small class="text-muted">DNI</small><br>
              {{ dataUsuario.dni }} <br>
              <small class="text-muted">Apellidos</small><br>
              {{ dataUsuario.apellidos }} <br>
              <small class="text-muted">Nombres</small><br>
              {{ dataUsuario.nombres }}
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <b-form-file
                id="usuarioFotografia"
                v-model="fileFoto"
                size="sm"
                accept=".jpg, .png, .jpeg, .gif, .svg"
              />
            </b-col>
          </b-row>

        </b-form-group>
      </b-col>

      <b-col
        lg="6"
        md="12"
        sm="12"

        class="border rounded pt-1 mb-1"
      >
        <b-form-group
          label="Firma"
          label-for="usuarioFirma"
        >
          <b-img
            v-if="firmaUsuario"
            thumbnail
            center
            fluid
            :src="firmaUsuario"
            alt="Image 1"
            width="120"
            style="margin-bottom:5px;"
          />
          <h4
            v-else
            class="text-center text-danger"
          >
            NO EXISTE FIRMA
          </h4>
          <b-form-file
            id="usuarioFirma"
            v-model="fileFirma"
            size="sm"
            accept=".jpg, .png, .jpeg, .gif, .svg"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between mt-3 border-top pt-1">
      <b-button
        variant="outline-danger"
        @click="$bvModal.hide('modal_actualizar_perfil')"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        :disabled="fileFoto===null && fileFirma===null ? true : false"
        @click="actualizarPerfil()"
      >
        Actualizar
      </b-button>
    </div>

  </b-modal>
</template>

<script>

import {
  BButton, BModal, BFormGroup, BCol, BRow, BImg, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BImg,
    BCol,
    BRow,
    BModal,
    BButton,
    BFormGroup,
    BFormFile,
  },

  props: {
    fotografiaUsuario: {
      type: String,
      default: null,
    },
    firmaUsuario: {
      type: String,
      default: null,
    },
    dataUsuario: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileFoto: null,
      fileFirma: null,
      usuario: {},
    }
  },
  created() {
    this.$parent.$on('abrirModalActualizarPerfil', this.abrirModalActualizarPerfil)
  },

  methods: {

    abrirModalActualizarPerfil() {
      // this.usuario = JSON.parse(localStorage.getItem('userData'))

      this.$bvModal.show('modal_actualizar_perfil')
    },

    actualizarPerfil() {
      const formData = new FormData()

      if (this.fileFoto) {
        formData.append('foto', this.fileFoto)
      }
      if (this.fileFirma) {
        formData.append('firma', this.fileFirma)
      }

      this.$http.post('/usuario/img-update', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Perfil actualizado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          this.$emit('updateFotoUsuario', res.data.data)
          this.fileFoto = null
          this.fileFirma = null
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error en actualizar perfil!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>
